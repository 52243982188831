import './footer.css'

const Footer = () => {
  return (
    <div className='footer'>
        <h1>Information</h1>
        <p>For more information, please contact me at leslie.th.nguyen@gmail.com</p>

    </div>
  )
}

export default Footer